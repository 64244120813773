import dchAPI from '@/services/dchAPI';
import { demoAssets } from '@/services/demo.js';

const state = {
  assets: [],
};

const getters = {
  allAssets: (state) => state.assets.sort((a, b) => a.name.localeCompare(b.name)),
  enabledAssets: (state) => {
    var a = state.assets
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((asset) => asset.enabled);
    return a;
  },
  assetsBySource: (state) => (source) => {
    return state.assets
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((asset) => asset.enabled && asset.sources.some((s) => s.handle === source));
  },
  assetsByMultipleSources: (state) => (sourceIds) => {
    // Receives an array of source ids
    return state.assets.filter((asset) =>
      sourceIds.some((source) => asset.sources.some((s) => s.handle === source))
    );
  },
  oneAsset: (state) => (id) => {
    return state.assets.find((asset) => asset.asset_id == id);
  },
};

const actions = {
  async fetchAssetsData({ commit }) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get('assets')
        .then((response) => {
          const responseData = response.data.data.map((r, index) =>
            transformAssetsResponseData(r, index)
          );
          commit('setAssetsData', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchAssetsBySource({ commit }, source) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get(`assets?source=${source}`)
        .then((response) => {
          const responseData = response.data.data.map((r, index) =>
            transformAssetsResponseData(r, index)
          );
          commit('setAssetsData', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setAssetsData: (state, assets) => {
    state.assets =
      process.env.VUE_APP_IS_DEMO === 'true'
        ? assets.filter((obj) => demoAssets.map((a) => a.imo).includes(obj.asset_id))
        : assets;
  },
};

const transformAssetsResponseData = (data) => {
  return {
    ...{ enabled: true },
    ...data,
    id: data.asset_id,
    identifier: data.asset_id,
    active: false,
    clicked: false,
    not_connected: false,
    central: false,
    hidden: false,
    show_only_connected_to: false,
    name:
      process.env.VUE_APP_IS_DEMO === 'true' &&
      demoAssets.find((a) => a.imo === data.asset_id) !== undefined
        ? demoAssets.find((a) => a.imo === data.asset_id).name.toUpperCase()
        : data.name.toUpperCase(),
  };
};

export default {
  state,
  getters,
  actions,
  mutations,
};
