import { demoConfig } from '@/services/demo.js';
class configService {
  outcomeAPITypes() {
    return [{ id: 1, name: 'Ankeri API' }];
  }

  connectionsConfig() {
    return [
      {
        type: 'assets',
        avatar_color: 'cyan',
        icon_color: 'white',
        icon: 'anchor',
        label: 'assets',
        sub_info_handle: 'id',
        has_path: true,
        path_handle: 'assetItem',
      },
      {
        type: 'sources',
        avatar_color: 'orange',
        icon_color: 'white',
        icon: 'earth-arrow-right',
        label: 'sources',
        sub_info_handle: 'type',
        has_path: true,
        path_handle: 'sourceItem',
      },
      {
        type: 'tags',
        avatar_color: 'green',
        icon_color: 'white',
        icon: 'tag-outline',
        label: 'tags',
        sub_info_handle: 'id',
        has_path: false,
      },
      {
        type: 'outcomes',
        avatar_color: 'blue',
        icon_color: 'white',
        icon: 'api',
        label: 'outcomes',
        sub_info_handle: 'endpoints',
        has_path: true,
        path_handle: 'outcomeItem',
      },
    ];
  }

  outcomeConfig() {
    return this.connectionsConfig().find((c) => c.type === 'outcomes');
  }

  sourceConfig() {
    return this.connectionsConfig().find((c) => c.type === 'sources');
  }

  assetConfig() {
    return this.connectionsConfig().find((c) => c.type === 'assets');
  }

  availableSources() {
    let config = [
      {
        name: 'Marorka',
        id: 'marorka',
        logo: './marorka.jpeg',
        background_color: 'surface',
        is_enabled: false,
        type: 'autologged',
      },
      {
        name: 's-Insight',
        id: 'navigator_insight',
        logo: './navigator_insight.png',
        background_color: 'surface',
        is_enabled: false,
        type: 'reported',
      },
      {
        name: 'Vesseltracker',
        id: 'vesseltracker',
        logo: './vessel_tracker.png',
        background_color: 'white',
        is_enabled: false,
        type: 'ais',
      },
      {
        name: 'Inmarsat',
        id: 'inmarsat',
        logo: './inmarsat.png',
        background_color: 'white',
        width: 60,
        is_enabled: false,
        type: 'autologged',
      },
      {
        name: 'Hoppe',
        id: 'hoppe',
        logo: './hoppe.png',
        background_color: 'white',
        width: 56,
        is_enabled: false,
        type: 'autologged',
      },
      {
        name: 'MAC Online System',
        id: 'mac_autolog',
        logo: './mac_system_solutions.png',
        background_color: 'white',
        width: 56,
        is_enabled: false,
        type: 'autologged',
      },
      {
        name: 'MAC Reporting System',
        id: 'mac_reported',
        logo: './mac_system_solutions.png',
        background_color: 'white',
        width: 56,
        is_enabled: false,
        type: 'reported',
      },
      {
        name: 'MAC Voyages',
        id: 'mac_voyages',
        logo: './mac_system_solutions.png',
        background_color: 'white',
        width: 56,
        is_enabled: false,
        type: 'voyages',
      },
      {
        name: 'APEX',
        id: 'apex',
        logo: './ankeri_logo.png',
        background_color: 'white',
        width: 56,
        is_enabled: false,
        type: 'reported',
      },
      {
        name: 'Bluetracker',
        id: 'bluetracker',
        logo: './navis.png',
        background_color: '#4d6074',
        is_enabled: false,
        type: 'autologged',
      },
      {
        name: 'Ocean Sync',
        id: 'oceansync',
        logo: './oceansync_logo.png',
        background_color: 'surface',
        is_enabled: false,
        type: 'autologged',
      },
      {
        name: 'Hanseaticsoft',
        id: 'hanseaticsoft',
        logo: './hanseaticsoft_logo.png',
        background_color: 'surface',
        is_enabled: false,
        type: 'reported',
      },
      {
        name: 'Onelink Performance',
        id: 'onelink',
        logo: './onelink.png',
        background_color: 'white',
        width: 56,
        is_enabled: false,
        type: 'reported',
      },
      {
        name: 'Klappir',
        id: 'klappir',
        logo: './klappir_logo.jpeg',
        background_color: 'surface',
        is_enabled: false,
        type: 'reported',
      },
      {
        name: 'Vessel Insight',
        id: 'vessel_insight',
        logo: './kongsberg_logo.png',
        background_color: '#d71539',
        width: '50',
        is_enabled: false,
        type: 'autologged',
      },
      {
        name: 'Sertica',
        id: 'sertica',
        logo: './Sertica.png',
        background_color: 'white',
        width: '50',
        is_enabled: false,
        type: 'reported',
      },
      {
        name: 'Veracity by DNV',
        id: 'dnv_voyages',
        logo: './veracity.png',
        background_color: '#002a3e',
        width: '50',
        is_enabled: false,
        type: 'reported',
      },
      {
        name: 'Emission+',
        id: 'mac_voyages',
        logo: './emission_plus.png',
        background_color: 'white',
        width: '50',
        is_enabled: false,
        type: 'reported',
      },
    ];
    if (process.env.VUE_APP_IS_DEMO === 'true') {
      const demoConfigIds = demoConfig.map((obj) => obj.id);
      config = config.filter((obj) => demoConfigIds.includes(obj.id) !== true);
      config = [...config, ...demoConfig];
    }
    return config;
  }

  availableAPITypes() {
    return [
      {
        id: 'aggregations',
        label: 'Aggregation API',
        description: 'The Aggregation API provides aggregated data',
      },
      {
        id: 'timeseries',
        label: 'Time series API',
        description: 'The Time series API provides time series data',
      },
      {
        id: 'emissions',
        label: 'Emissions API',
        description:
          'The Emissions API provides data on greenhouse gas emissions and carbon intensity index',
      },
      {
        id: 'voyages',
        label: 'Voyages API',
        description: 'The Voyages API provides voyages data',
      },
    ];
  }

  companies() {
    return [
      {
        company_id: 'cf1c03f0-3dd1-419a-b4d4-1483ad6f4c66',
        name: 'Hapag-Lloyd',
        branding_color: '#f60',
      },
      {
        company_id: '2dc3026b-2ee9-49bf-9d58-1e1c5d74c63a',
        name: 'Reederei NSB',
        branding_color: '#253174',
      },
      {
        company_id: '3f481bd5-ecfe-4533-8e9a-ef5aa0b0c10e',
        name: 'Eimskip',
        branding_color: '#1b61b1',
      },
      {
        company_id: '1f2d7515-81ae-4da7-955d-9fa2389bcf12',
        name: 'Reederei Jens & Waller',
        branding_color: '#312d5a',
      },
      {
        company_id: 'e7c83960-2907-48c2-ae35-ebad8a44979f',
        name: 'Ernst Russ',
        branding_color: '#FA1412',
      },
      {
        company_id: '95b6cd13-64f9-4117-aca6-9202db1a4561',
        name: 'Technomar Shipping',
        branding_color: '#26328C',
      },
      {
        company_id: 'd4f3c799-66ae-493f-aefb-457f02281919',
        name: 'Langh Ship',
        branding_color: '#E30720',
      },
      {
        company_id: '610a4ffd-be27-4f48-ab1f-28a2f05cbbaf',
        name: 'The Asian Spirit Steamship Company',
        branding_color: '#B52819',
      },
      {
        company_id: '885749c6-5622-4b8b-a542-12cae80ab952',
        name: 'USC Barnkrug',
        branding_color: '#292B6E',
      },
      {
        company_id: '6cfc9d2c-c6aa-4df5-b1d1-a6e77f82abd7',
        name: 'Nordic° Hamburg',
        branding_color: '#73CAEB',
      },
    ];
  }
}

export default new configService();
